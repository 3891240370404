<template>
  <div class="content fs-6 d-flex flex-column flex-column-fluid">
    <div class="toolbar">
      <div class="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
        <div
          class="d-flex flex-column align-items-start justify-content-center flex-wrap me-2"
        >
          <h1 class="text-dark fw-bolder my-1 fs-2">
            Formulaire <span v-if="promoter == null">d'ajout </span>
            <span v-else>de modification </span>d'un promoteur
          </h1>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="card">
        <div
          class="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_basic_info"
          aria-expanded="true"
          aria-controls="kt_account_basic_info"
        >
          <div class="card-title m-0">
            <h3 class="fw-boldest m-0">Informations basiques</h3>
          </div>
        </div>
        <div class="collapse show">
          <form class="form" @submit.prevent="createAddressThenPromoter()">
            <div class="card-body border-top p-9">
              <div class="row mb-6">
                <label class="col-lg-2 col-form-label required fw-bold fs-6"
                  >Nom du promoteur</label
                >
                <div class="col-lg-10 fv-row">
                  <input
                    v-model="form.name"
                    type="text"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="Nom du promoteur"
                  />
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-2 col-form-label required fw-bold fs-6"
                  >Nom et prénom du dirigeant</label
                >
                <div class="col-lg-10 fv-row">
                  <input
                    v-model="form.director_name"
                    type="text"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="Nom et prénom du dirigeant"
                  />
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-2 col-form-label required fw-bold fs-6"
                  >Adresse siège</label
                >
                <div class="col-lg-10 fv-row">
                  <AddressInput
                    :can-create-address="canCreateAddress"
                    :address-selected="promoter != null && sendAddress"
                    @addressCreated="catchAddressId"
                  ></AddressInput>
                </div>
              </div>
            </div>
            <div class="card-footer d-flex justify-content-end py-6 px-9">
              <router-link
                to="/promoters?size=30&page=1"
                class="btn btn-light btn-active-light-primary me-2"
              >
                Retour
              </router-link>
              <button type="submit" class="btn btn-primary">
                <span v-if="promoter == null">Ajouter</span>
                <span v-else>Modifier</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useRoute } from "vue-router";
import AddressInput from "@/components/selects/AddressInput";

export default {
  name: "PromotersForm",
  components: {
    AddressInput,
  },
  data() {
    return {
      form: {
        id: null,
        name: "",
        director_name: "",
        head_office_address: "",
      },
      canCreateAddress: false,
      sendAddress: {},
    };
  },
  computed: {
    ...mapGetters(["promoter"]),
  },
  methods: {
    ...mapActions(["postPromoterWithAction"]),
    ...mapActions(["updatePromoter"]),
    ...mapActions(["getPromoter"]),
    async createAddressThenPromoter() {
      await this.createAdrress();
      this.canCreateAddress = false;
    },
    createAdrress() {
      this.canCreateAddress = true;
    },
    catchAddressId(value) {
      this.form.head_office_address = value;
      this.canCreateAddress = false;

      this.promoter == null
        ? this.postPromoterWithAction(this.form)
        : this.modifyPromoter();
    },
    modifyPromoter() {
      if (this.form.head_office_address == this.promoter.head_office_address) {
        this.form.head_office_address = this.promoter.head_office_address.id;
      }
      this.updatePromoter(this.form);
    },
    async showPromoter(id) {
      await this.getPromoter(id);
      this.form.id = this.promoter.id;
      this.form.name = this.promoter.name;
      this.form.director_name = this.promoter.director_name;
      this.form.head_office_address = this.promoter.head_office_address;
      this.sendAddress = this.promoter.head_office_address;
    },
    selectAddressOption(element) {
      this.form.head_office_address = element;
    },
  },
  // eslint-disable-next-line vue/order-in-components
  mounted() {
    const route = useRoute();
    if (route.params.id) {
      if (route.params.id != "add") {
        this.showPromoter(route.params.id);
      }
    }
  },
};
</script>
